import React, { useState, useMemo } from 'react';
import { ChakraProvider, Box, VStack, Heading, Input, Button, Text, Spinner, Tabs, TabList, TabPanels, Tab, TabPanel, Checkbox, Select, HStack, Alert, AlertIcon, Textarea } from '@chakra-ui/react';
import { fetchTweets, generatePost, fetchYoutubeTranscripts } from './api';
import type { Tweet, TranscriptData } from './api';
import axios from 'axios';

type TweetType = 'Tweet' | 'Reply' | 'Quote';

function isTweetType(type: string): type is TweetType {
  return ['Tweet', 'Reply', 'Quote'].includes(type);
}

function App() {
  const [usernames, setUsernames] = useState('');
  const [youtubeChannel, setYoutubeChannel] = useState('');
  const [tweets, setTweets] = useState<Record<string, Tweet[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState<Record<TweetType, boolean>>({
    Tweet: true,
    Reply: true,
    Quote: true
  });
  const [sortBy, setSortBy] = useState<'date' | 'likes' | 'retweets'>('date');
  const [numTweets, setNumTweets] = useState<number>(10);
  const [generatedTweet, setGeneratedTweet] = useState('');
  const [mainAccount, setMainAccount] = useState('');
  const [referenceAccounts, setReferenceAccounts] = useState('');
  const [youtubeTranscripts, setYoutubeTranscripts] = useState<TranscriptData[]>([]);

  const handleFetchTweets = async () => {
    setIsLoading(true);
    setError('');
    try {
      console.log('Fetching tweets for:', usernames, 'Number of tweets:', numTweets);
      const result = await fetchTweets(usernames, numTweets);
      console.log('Fetched tweets:', result);
      setTweets(result);
    } catch (err) {
      console.error('Error fetching tweets:', err);
      setError(`ツイートの取得中にエラーが発生しました: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGeneratePost = async () => {
    setIsLoading(true);
    setError('');
    try {
      const result = await generatePost(mainAccount, referenceAccounts.split(','));
      setGeneratedTweet(result);
    } catch (err) {
      let errorMessage = 'ツイートの生成中に予期せぬエラーが発生しました。';
      if (axios.isAxiosError(err) && err.response) {
        errorMessage = `サーバーエラー: ${err.response.status} - ${err.response.data.message || err.message}`;
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }
      setError(`ツイートの生成中にエラーが発生しました: ${errorMessage}`);
      console.error('Generate Post Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchYoutubeTranscripts = async () => {
    setIsLoading(true);
    setError('');
    try {
      const result = await fetchYoutubeTranscripts(youtubeChannel, 5); // 5は取得する動画の数
      setYoutubeTranscripts(result);
    } catch (err) {
      setError(`YouTube文字起こしの取得中にエラーが発生しました: ${err instanceof Error ? err.message : String(err)}`);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getTweetColor = (tweetType: TweetType) => {
    switch(tweetType) {
      case 'Reply': return 'blue.100';
      case 'Quote': return 'green.100';
      default: return 'gray.100';
    }
  };

  const handleFilterChange = (tweetType: TweetType) => {
    setFilters(prev => ({...prev, [tweetType]: !prev[tweetType]}));
  };

  const sortedAndFilteredTweets = useMemo(() => {
    return Object.entries(tweets).reduce((acc, [username, userTweets]) => {
      const filteredTweets = userTweets.filter(tweet => {
        if (isTweetType(tweet.tweet_type)) {
          return filters[tweet.tweet_type];
        }
        return false;
      });
      const sortedTweets = [...filteredTweets].sort((a, b) => {
        if (sortBy === 'likes') return b.like_count - a.like_count;
        if (sortBy === 'retweets') return b.retweet_count - a.retweet_count;
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
      acc[username] = sortedTweets;
      return acc;
    }, {} as Record<string, Tweet[]>);
  }, [tweets, filters, sortBy]);

  return (
    <ChakraProvider>
      <Box maxWidth="800px" margin="auto" padding={5}>
        <VStack spacing={4} align="stretch">
        <Heading as="h1" size="xl" textAlign="center">シュビヒロX事業部用システム (テスト)</Heading>
          
          <Tabs>
            <TabList>
              <Tab>ツイート取得</Tab>
              <Tab>ツイート生成</Tab>
              <Tab>YouTube文字起こし</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack spacing={4}>
                  <Input 
                    placeholder="Twitterのユーザー名を入力（カンマ区切り）" 
                    value={usernames} 
                    onChange={(e) => setUsernames(e.target.value)}
                  />
                  <Input 
                    type="number" 
                    placeholder="取得するツイート数" 
                    value={numTweets} 
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (!isNaN(value) && value > 0 && value <= 100) {
                        setNumTweets(value);
                      }
                    }}
                    min={1}
                    max={100}
                  />

                  <Button 
                    colorScheme="blue" 
                    onClick={handleFetchTweets} 
                    isLoading={isLoading}
                    loadingText="ツイート取得中"
                  >
                    ツイートを取得
                  </Button>
                  <HStack>
                    <Checkbox isChecked={filters.Tweet} onChange={() => handleFilterChange('Tweet')}>ツイート</Checkbox>
                    <Checkbox isChecked={filters.Reply} onChange={() => handleFilterChange('Reply')}>返信</Checkbox>
                    <Checkbox isChecked={filters.Quote} onChange={() => handleFilterChange('Quote')}>引用</Checkbox>
                    <Select value={sortBy} onChange={(e) => setSortBy(e.target.value as 'date' | 'likes' | 'retweets')}>
                      <option value="date">日付順</option>
                      <option value="likes">いいね数順</option>
                      <option value="retweets">リツイート数順</option>
                    </Select>
                  </HStack>
                  {Object.entries(sortedAndFilteredTweets).map(([username, userTweets]) => (
                    <VStack key={username} align="stretch">
                      <Heading size="md">{username}</Heading>
                      {userTweets.map((tweet, index) => (
                        <Box key={index} p={4} borderWidth={1} borderRadius="md" bg={getTweetColor(tweet.tweet_type as TweetType)}>
                          <Text>{tweet.text}</Text>
                          <Text fontSize="sm" color="gray.500">
                            {new Date(tweet.created_at).toLocaleString()} | 
                            いいね: {tweet.like_count} | リツイート: {tweet.retweet_count}
                          </Text>
                        </Box>
                      ))}
                    </VStack>
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={4}>
                  <Input 
                    placeholder="メインアカウント" 
                    value={mainAccount} 
                    onChange={(e) => setMainAccount(e.target.value)}
                  />
                  <Input 
                    placeholder="参考アカウント（カンマ区切り）" 
                    value={referenceAccounts} 
                    onChange={(e) => setReferenceAccounts(e.target.value)}
                  />
                  <Button 
                    colorScheme="green" 
                    onClick={handleGeneratePost} 
                    isLoading={isLoading}
                    loadingText="ツイート生成中"
                  >
                    ツイートを生成
                  </Button>
                  {generatedTweet && (
                    <Textarea value={generatedTweet} isReadOnly />
                  )}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={4}>
                  <Input 
                    placeholder="YouTubeチャンネル名" 
                    value={youtubeChannel} 
                    onChange={(e) => setYoutubeChannel(e.target.value)}
                  />
                  <Button 
                    colorScheme="red" 
                    onClick={handleFetchYoutubeTranscripts} 
                    isLoading={isLoading}
                    loadingText="文字起こし取得中"
                  >
                    YouTube文字起こしを取得
                  </Button>
                  {youtubeTranscripts.map((transcript, index) => (
                    <Box key={index}>
                      <Text>Video ID: {transcript.video_id}</Text>
                      <Text noOfLines={3}>{transcript.transcript}</Text>
                    </Box>
                  ))}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
          
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {isLoading && <Spinner />}
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;