import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://twitter-backend-672970500274.asia-northeast1.run.app';

export interface Tweet {
  id: string;
  created_at: string;
  text: string;
  like_count: number;
  retweet_count: number;
  tweet_type: 'Tweet' | 'Reply' | 'Quote';
}

export interface TranscriptData {
  video_id: string;
  transcript: string;
}

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
});

export const fetchTweets = async (usernames: string, numTweets: number): Promise<Record<string, Tweet[]>> => {
  const response = await api.get<Record<string, Tweet[]>>(`/api/tweets?usernames=${usernames}&num_tweets=${numTweets}`);
  return response.data;
};

export const generatePost = async (mainAccount: string, referenceAccounts: string[]): Promise<string> => {
  const response = await api.post<{ generated_tweet: string }>('/api/generate_post', {
    main_account: mainAccount,
    reference_accounts: referenceAccounts,
  });
  return response.data.generated_tweet;
};

export const fetchYoutubeTranscripts = async (channelName: string, numVideos: number): Promise<TranscriptData[]> => {
  const response = await api.post<{ transcripts: TranscriptData[] }>('/api/youtube_transcripts', {
    channel_name: channelName,
    num_videos: numVideos,
  });
  return response.data.transcripts;
};